{{#ifEquals products.length 0}}
{{else}}
<li class="menuItem {{#if children}}toggle js-tog{{/if}}">
    {{#if href}}
        {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
        <a href="{{href}}" class="menuItem-link">{{label}}</a>
    {{else}}
        {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
        <div class="menuItem-lbl">{{label}}</div>
    {{/if}}
    {{#if children}}
        <ul class="menuItem-list {{#if children}}toggle-target{{/if}}">
            {{#each children}}
                <li class="menuItem-list-child {{#if children}}toggle js-tog{{/if}}">
                        {{#if href}}
                            {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
                            <a href="{{href}}" class="menuItem-list-child-link">{{label}}</a>
                        {{else}}
                            {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
                            <div class="menuItem-list-child-lbl">{{label}}</div>
                        {{/if}}
                    {{#if children}}
                        <ul class="menuItem-list-child-list {{#if children}}toggle-target{{/if}}">
                            {{#each children}}
                                <li class="menuItem-list-child-list-grandChild">
                                    {{#if href}}
                                        <a href="{{href}}" class="menuItem-list-child-list-grandChild-link">{{label}}</a>
                                    {{else}}
                                        <div class="menuItem-list-child-list-grandChild-lbl">{{label}}</div>
                                    {{/if}}
                                </li>
                            {{/each}}
                        </ul>
                    {{/if}}
                </li>
            {{/each}}
        </ul>
    {{/if}}
</li>
{{/ifEquals}}